import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "btn-group-vertical" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("button", {
      title: "Add all",
      onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.addAll(...args))),
      class: "btn btn-light"
    }, " >> "),
    _createVNode("button", {
      title: "Add selected (Ctrl + LClick)",
      onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.addSelected(...args))),
      class: "btn btn-light"
    }, " > "),
    _createVNode("button", {
      title: "Backup selected (Ctrl + LClick)",
      onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.backupSelected(...args))),
      class: "btn btn-light"
    }, " < "),
    _createVNode("button", {
      title: "Backup all",
      onClick: _cache[4] || (_cache[4] = (...args) => (_ctx.backupAll(...args))),
      class: "btn btn-light"
    }, " << ")
  ]))
}