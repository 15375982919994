import { resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 3 }

export function render(_ctx, _cache) {
  const _component_tank_icon = _resolveComponent("tank-icon")
  const _component_damage_icon = _resolveComponent("damage-icon")
  const _component_support_icon = _resolveComponent("support-icon")

  return (_ctx.rtype === 'tank')
    ? _createVNode(_component_tank_icon, { key: 0 })
    : (_ctx.rtype === 'dps')
      ? _createVNode(_component_damage_icon, { key: 1 })
      : (_ctx.rtype === 'support')
        ? _createVNode(_component_support_icon, { key: 2 })
        : (_openBlock(), _createBlock("span", _hoisted_1))
}