
import { computed, defineComponent, ref } from 'vue';

import { useStore } from '@/store';

export default defineComponent({
  name: 'TriesCount',
  setup() {
    const store = useStore();
    const storeBalancerOptions = computed(() => store.state.balancerOptions);
    const balancerOptions = ref(storeBalancerOptions);

    return { balancerOptions };
  },
});
