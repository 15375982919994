
import { computed, defineComponent } from 'vue';

import { useStore } from '@/store';

export default defineComponent({
  name: 'BalancerProgress',
  props: {
    progress: Object,
  },
  setup(props) {
    const store = useStore();
    const triesCount = computed(() => store.state.balancerOptions.triesCount);

    const current = computed(() =>
      props.progress ? props.progress.current / (props.progress.total * triesCount.value) : 0
    );

    return { current };
  },
});
