
import { computed, defineComponent, PropType, ref } from 'vue';
import { useStore } from '@/store';

import Bezier from '@/components/Helpers/Bezier.vue';
import SpecificationIcon from '../svg/SpecificationIcon.vue';

export default defineComponent({
  name: 'AdjustRole',
  components: { Bezier, SpecificationIcon },
  props: {
    role: String as PropType<'tank' | 'support' | 'dps'>,
  },
  setup() {
    const active = ref<'any' | 'primary' | 'secondary'>('any');
    const store = useStore();
    const storeOptions = computed(() => store.state.balancerOptions.adjustSr);
    const adjustSr = ref(storeOptions);

    const changeActive = (to: 'any' | 'primary' | 'secondary') => {
      active.value = to;
    };

    return { active, adjustSr, changeActive };
  },
});
