
import { computed, defineComponent } from 'vue';
import { useStore } from '@/store';
import MutationTypes from '@/store/mutation-types';

import Modal from '@/components/Helpers/Modal.vue';
import Result from '@/components/Teams/Result.vue';
import { Balance } from '@/objects/balance';

export default defineComponent({
  name: 'ResultSelection',
  components: { Modal, Result },
  setup() {
    const store = useStore();
    const isActive = computed(() => store.state.isSelection);
    const results = computed(() =>
      store.state.balancerResults
        .filter(v => v.leftovers.length === 0)
        .sort((a, b) => a.dispersion - b.dispersion)
        .concat(
          store.state.balancerResults
            .filter(v => v.leftovers.length !== 0)
            .sort((a, b) => a.dispersion - b.dispersion)
        )
    );

    const closeModal = () => {
      store.commit(MutationTypes.TOGGLE_SELECTION, undefined);
    };

    const storeTeams = computed(() => store.state.teams);
    const isActiveBalance = (balance: Balance) => {
      return (
        storeTeams.value.map(t => t.uuid).join('|') === balance.teams.map(t => t.uuid).join('|')
      );
    };

    return { isActive, closeModal, results, isActiveBalance };
  },
});
