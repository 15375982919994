
import { defineComponent } from 'vue';

import Dropdown from '@/components/Helpers/Dropdown.vue';
import DropItem from '@/components/Helpers/DropItem.vue';

export default defineComponent({
  name: 'Sort',
  components: { Dropdown, DropItem },
});
