
import '@/styles/main.scss';
import { computed, defineComponent } from 'vue';

import Swap from '@/components/Swap.vue';
import Lobby from '@/components/Lobby.vue';
import Teams from '@/components/Teams.vue';
import OWIcon from '@/components/svg/OWIcon.vue';
import BackupLobby from '@/components/BackupLobby.vue';
import { useStore } from './store';
import MutationTypes from './store/mutation-types';

export default defineComponent({
  name: 'App',
  components: { Lobby, Teams, OWIcon, BackupLobby, Swap },
  setup() {
    const store = useStore();

    const toggleBackup = () => {
      store.commit(MutationTypes.TOGGLE_BACKUP, undefined);
    };

    const displayBackup = computed(() => store.state.showBackup);

    return { displayBackup, toggleBackup };
  }
});
