
import { defineComponent } from 'vue';
import { useStore } from '@/store';

import Dropdown from '@/components/Helpers/Dropdown.vue';
import DropItem from '@/components/Helpers/DropItem.vue';
import MutationTypes from '@/store/mutation-types';

export default defineComponent({
  name: 'Assign',
  components: { Dropdown, DropItem },
  setup() {
    const store = useStore();

    const assignAuto = () => {
      store.commit(MutationTypes.CLEAR_ALL_EXTRA, undefined);
      store.commit(MutationTypes.ASSIGN_CAPTAINS, 0);
      store.commit(MutationTypes.ASSIGN_SQUIRES, 5500);
    };

    const assignCaptains = () => {
      // eslint-disable-next-line
      const captainSR = +(prompt("Enter captain's minimum SR", '3600') || 0);
      store.commit(MutationTypes.ASSIGN_CAPTAINS, captainSR);
    };

    const assignSquires = () => {
      // eslint-disable-next-line
      const squireSR = +(prompt("Enter suire's maximum SR", '1700') || 0);
      store.commit(MutationTypes.ASSIGN_SQUIRES, squireSR);
    };

    const clearCaptains = () => {
      store.commit(MutationTypes.CLEAR_CAPTAINS, undefined);
    };

    const clearSquires = () => {
      store.commit(MutationTypes.CLEAR_SQUIRES, undefined);
    };

    const clearAll = () => {
      store.commit(MutationTypes.CLEAR_ALL_EXTRA, undefined);
    };

    return {
      assignAuto,
      assignCaptains,
      assignSquires,
      clearCaptains,
      clearSquires,
      clearAll,
    };
  },
});
