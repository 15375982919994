import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache) {
  const _component_edit_role = _resolveComponent("edit-role")
  const _component_sortable = _resolveComponent("sortable")

  return (_openBlock(), _createBlock(_component_sortable, {
    "onUpdate-position": _ctx.updatePosition,
    handle: ".sortable-handler"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.roles, (role) => {
        return (_openBlock(), _createBlock(_component_edit_role, {
          key: role.role,
          rtype: role.role,
          role: _ctx.playerStats.classes[role.role],
          "onUpdate-rank": (role, rank) => _ctx.$emit('update-rank', role, rank),
          "onUpdate-specialization": _cache[1] || (_cache[1] = (...data) => _ctx.$emit('update-specialization', ...data))
        }, null, 8, ["rtype", "role", "onUpdate-rank"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1
  }, 8, ["onUpdate-position"]))
}