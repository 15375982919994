import { resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_tank_icon = _resolveComponent("tank-icon")
  const _component_main_tank_icon = _resolveComponent("main-tank-icon")
  const _component_off_tank_icon = _resolveComponent("off-tank-icon")
  const _component_damage_icon = _resolveComponent("damage-icon")
  const _component_hitscan_icon = _resolveComponent("hitscan-icon")
  const _component_projectile_icon = _resolveComponent("projectile-icon")
  const _component_support_icon = _resolveComponent("support-icon")
  const _component_heal_icon = _resolveComponent("heal-icon")
  const _component_light_heal_icon = _resolveComponent("light-heal-icon")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.rtype === 'tank' && _ctx.specification === 'any')
      ? _createVNode(_component_tank_icon, { key: 0 })
      : _createCommentVNode("", true),
    (_ctx.rtype === 'tank' && _ctx.specification === 'primary')
      ? _createVNode(_component_main_tank_icon, { key: 0 })
      : _createCommentVNode("", true),
    (_ctx.rtype === 'tank' && _ctx.specification === 'secondary')
      ? _createVNode(_component_off_tank_icon, { key: 0 })
      : _createCommentVNode("", true),
    (_ctx.rtype === 'dps' && _ctx.specification === 'any')
      ? _createVNode(_component_damage_icon, { key: 0 })
      : _createCommentVNode("", true),
    (_ctx.rtype === 'dps' && _ctx.specification === 'primary')
      ? _createVNode(_component_hitscan_icon, { key: 0 })
      : _createCommentVNode("", true),
    (_ctx.rtype === 'dps' && _ctx.specification === 'secondary')
      ? _createVNode(_component_projectile_icon, { key: 0 })
      : _createCommentVNode("", true),
    (_ctx.rtype === 'support' && _ctx.specification === 'any')
      ? _createVNode(_component_support_icon, { key: 0 })
      : _createCommentVNode("", true),
    (_ctx.rtype === 'support' && _ctx.specification === 'primary')
      ? _createVNode(_component_heal_icon, { key: 0 })
      : _createCommentVNode("", true),
    (_ctx.rtype === 'support' && _ctx.specification === 'secondary')
      ? _createVNode(_component_light_heal_icon, { key: 0 })
      : _createCommentVNode("", true)
  ], 64 /* STABLE_FRAGMENT */))
}