import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = { class: "input-group-text cursor-pointer sortable-handler" }
const _hoisted_3 = { class: "input-group-text" }
const _hoisted_4 = { class: "input-group-text" }
const _hoisted_5 = { class: "input-group-text w-7rem" }
const _hoisted_6 = { class: "input-group-text w-7rem" }

export function render(_ctx, _cache) {
  const _component_role_icon = _resolveComponent("role-icon")
  const _component_rank_icon = _resolveComponent("rank-icon")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("span", _hoisted_2, [
      _createVNode(_component_role_icon, { rtype: _ctx.rtype }, null, 8, ["rtype"])
    ]),
    _createVNode("span", _hoisted_3, [
      _createVNode(_component_rank_icon, {
        rank: _ctx.mRole.rank
      }, null, 8, ["rank"])
    ]),
    _createVNode("span", _hoisted_4, [
      _withDirectives(_createVNode("input", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (_ctx.mRole.isActive = $event)),
        type: "checkbox",
        id: `${_ctx.rtype}_enabled`,
        class: "form-check-input",
        "aria-label": _ctx.rtype
      }, null, 8, ["id", "aria-label"]), [
        [_vModelCheckbox, _ctx.mRole.isActive]
      ])
    ]),
    _createVNode("span", _hoisted_5, [
      _createVNode("input", {
        type: "checkbox",
        class: "btn-check",
        name: "options",
        id: `option_main_${_ctx.rtype}`,
        autocomplete: "off",
        checked: _ctx.mRole.primary,
        onChange: _cache[2] || (_cache[2] = (...args) => (_ctx.changeSpecializationPrimary(...args)))
      }, null, 40, ["id", "checked"]),
      _createVNode("label", {
        class: "btn btn-sm btn-outline-dark",
        for: `option_main_${_ctx.rtype}`
      }, _toDisplayString(_ctx.roles[_ctx.rtype].primary), 9, ["for"])
    ]),
    _createVNode("span", _hoisted_6, [
      _createVNode("input", {
        type: "checkbox",
        class: "btn-check",
        name: "options",
        id: `option_off_${_ctx.rtype}`,
        autocomplete: "off",
        checked: _ctx.mRole.secondary,
        onInput: _cache[3] || (_cache[3] = (...args) => (_ctx.changeSpecializationSecondary(...args)))
      }, null, 40, ["id", "checked"]),
      _createVNode("label", {
        class: "btn btn-sm btn-outline-dark",
        for: `option_off_${_ctx.rtype}`
      }, _toDisplayString(_ctx.roles[_ctx.rtype].secondary), 9, ["for"])
    ]),
    _createVNode("input", {
      type: "number",
      id: "level",
      class: "form-control",
      min: "0",
      value: _ctx.mRole.rank,
      onInput: _cache[4] || (_cache[4] = (...args) => (_ctx.inpChange(...args)))
    }, null, 40, ["value"])
  ]))
}