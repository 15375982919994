import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_bin_icon = _resolveComponent("bin-icon")

  return (_openBlock(), _createBlock("button", {
    onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.deletePlayers(...args))),
    type: "button",
    class: "btn btn-sm btn-danger"
  }, [
    _createVNode(_component_bin_icon)
  ]))
}