import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "progress" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", {
        class: ["progress-bar progress-bar-striped", {
          'progress-bar-animated': _ctx.current !== 1,
        }],
        role: "progressbar",
        "aria-valuenow": Math.floor(_ctx.current * 100),
        "aria-valuemin": "0",
        "aria-valuemax": "100",
        style: `width: ${Math.floor(_ctx.current * 100)}%`
      }, null, 14, ["aria-valuenow"])
    ])
  ]))
}