
import { computed, defineComponent } from 'vue';
import { useStore } from '@/store';

import Archive from '@/components/Archive.vue';
import Balance from '@/components/Balance.vue';
import Team from '@/components/Teams/Team.vue';
import Stats from '@/components/Teams/Stats.vue';
import Actions from '@/components/Teams/Actions.vue';
import ModeToggler from '@/components/Teams/ModeToggler.vue';
import ResultSelection from '@/components/Teams/ResultSelection.vue';

export default defineComponent({
  name: 'Teams',
  components: { Archive, Team, Balance, Stats, Actions, ModeToggler, ResultSelection },
  setup() {
    const store = useStore();
    const storeTeams = computed(() => store.state.teams);

    return { teams: storeTeams };
  },
});
