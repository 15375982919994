
import { computed, defineComponent } from 'vue';
import debounce from 'lodash/debounce';

import { useStore } from '@/store';
import Utils from '@/utils';

import BinIcon from '@/components/svg/BinIcon.vue';
import Modal from '@/components/Helpers/Modal.vue';
import MutationTypes from '@/store/mutation-types';
import CloudUpIcon from '@/components/svg/CloudUpIcon.vue';
import DownloadIcon from '@/components/svg/DownloadIcon.vue';
import ImportArchive from '@/components/Teams/ImportArchive.vue';

export default defineComponent({
  name: 'Archive',
  components: { Modal, BinIcon, CloudUpIcon, DownloadIcon, ImportArchive },
  setup() {
    const store = useStore();
    const isActive = computed(() => store.state.isArchive);
    const archive = computed(() => store.state.archive);

    const closeModal = () => {
      store.commit(MutationTypes.TOGGLE_ARCHIVE, undefined);
    };

    const saveCurrent = () => {
      store.commit(MutationTypes.SAVE_TO_ARCHIVE, undefined);
    };

    const removeFromArchive = (id: number) => {
      store.commit(MutationTypes.REMOVE_FROM_ARCHIVE, id);
    };

    const select = (id: number) => {
      store.commit(MutationTypes.SELECT_ARCHIVE, id);
    };

    const updateName = debounce((e: Event, id: number) => {
      const name = (e.target as HTMLInputElement).value;

      if (name) {
        store.commit(MutationTypes.UPDATE_ARCHIVE_NAME, {
          id,
          name,
        });
      }
    }, 1000);

    const download = (id: number) => {
      const exportData = {
        format: 'xva-1',
        data: archive.value[id],
      };

      Utils.download(`archive-${archive.value[id].name}`, JSON.stringify(exportData));
    };

    return {
      archive,
      isActive,
      closeModal,
      saveCurrent,
      removeFromArchive,
      select,
      download,
      updateName,
    };
  },
});
