import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/sdivisions2/div20.png'
import _imports_1 from '@/assets/sdivisions2/div19.png'
import _imports_2 from '@/assets/sdivisions2/div18.png'
import _imports_3 from '@/assets/sdivisions2/div17.png'
import _imports_4 from '@/assets/sdivisions2/div16.png'
import _imports_5 from '@/assets/sdivisions2/div15.png'
import _imports_6 from '@/assets/sdivisions2/div14.png'
import _imports_7 from '@/assets/sdivisions2/div13.png'
import _imports_8 from '@/assets/sdivisions2/div12.png'
import _imports_9 from '@/assets/sdivisions2/div11.png'
import _imports_10 from '@/assets/sdivisions2/div10.png'
import _imports_11 from '@/assets/sdivisions2/div9.png'
import _imports_12 from '@/assets/sdivisions2/div8.png'
import _imports_13 from '@/assets/sdivisions2/div7.png'
import _imports_14 from '@/assets/sdivisions2/div6.png'
import _imports_15 from '@/assets/sdivisions2/div5.png'
import _imports_16 from '@/assets/sdivisions2/div4.png'
import _imports_17 from '@/assets/sdivisions2/div3.png'
import _imports_18 from '@/assets/sdivisions2/div2.png'
import _imports_19 from '@/assets/sdivisions2/div1.png'


const _hoisted_1 = {
  key: 0,
  class: "s-2em",
  src: _imports_0
}
const _hoisted_2 = {
  key: 1,
  class: "s-2em",
  src: _imports_1
}
const _hoisted_3 = {
  key: 2,
  class: "s-2em",
  src: _imports_2
}
const _hoisted_4 = {
  key: 3,
  class: "s-2em",
  src: _imports_3
}
const _hoisted_5 = {
  key: 4,
  class: "s-2em",
  src: _imports_4
}
const _hoisted_6 = {
  key: 5,
  class: "s-2em",
  src: _imports_5
}
const _hoisted_7 = {
  key: 6,
  class: "s-2em",
  src: _imports_6
}
const _hoisted_8 = {
  key: 7,
  class: "s-2em",
  src: _imports_7
}
const _hoisted_9 = {
  key: 8,
  class: "s-2em",
  src: _imports_8
}
const _hoisted_10 = {
  key: 9,
  class: "s-2em",
  src: _imports_9
}
const _hoisted_11 = {
  key: 10,
  class: "s-2em",
  src: _imports_10
}
const _hoisted_12 = {
  key: 11,
  class: "s-2em",
  src: _imports_11
}
const _hoisted_13 = {
  key: 12,
  class: "s-2em",
  src: _imports_12
}
const _hoisted_14 = {
  key: 13,
  class: "s-2em",
  src: _imports_13
}
const _hoisted_15 = {
  key: 14,
  class: "s-2em",
  src: _imports_14
}
const _hoisted_16 = {
  key: 15,
  class: "s-2em",
  src: _imports_15
}
const _hoisted_17 = {
  key: 16,
  class: "s-2em",
  src: _imports_16
}
const _hoisted_18 = {
  key: 17,
  class: "s-2em",
  src: _imports_17
}
const _hoisted_19 = {
  key: 18,
  class: "s-2em",
  src: _imports_18
}
const _hoisted_20 = {
  key: 19,
  class: "s-2em",
  src: _imports_19
}
const _hoisted_21 = { key: 20 }

export function render(_ctx, _cache) {
  return (_ctx.rank <= 199)
    ? (_openBlock(), _createBlock("img", _hoisted_1))
    : (_ctx.rank <= 299)
      ? (_openBlock(), _createBlock("img", _hoisted_2))
      : (_ctx.rank <= 399)
        ? (_openBlock(), _createBlock("img", _hoisted_3))
        : (_ctx.rank <= 499)
          ? (_openBlock(), _createBlock("img", _hoisted_4))
          : (_ctx.rank <= 599)
            ? (_openBlock(), _createBlock("img", _hoisted_5))
            : (_ctx.rank <= 699)
              ? (_openBlock(), _createBlock("img", _hoisted_6))
              : (_ctx.rank <= 799)
                ? (_openBlock(), _createBlock("img", _hoisted_7))
                : (_ctx.rank <= 899)
                  ? (_openBlock(), _createBlock("img", _hoisted_8))
                  : (_ctx.rank <= 999)
                    ? (_openBlock(), _createBlock("img", _hoisted_9))
                    : (_ctx.rank <= 1099)
                      ? (_openBlock(), _createBlock("img", _hoisted_10))
                      : (_ctx.rank <= 1199)
                        ? (_openBlock(), _createBlock("img", _hoisted_11))
                        : (_ctx.rank <= 1299)
                          ? (_openBlock(), _createBlock("img", _hoisted_12))
                          : (_ctx.rank <= 1399)
                            ? (_openBlock(), _createBlock("img", _hoisted_13))
                            : (_ctx.rank <= 1499)
                              ? (_openBlock(), _createBlock("img", _hoisted_14))
                              : (_ctx.rank <= 1599)
                                ? (_openBlock(), _createBlock("img", _hoisted_15))
                                : (_ctx.rank <= 1699)
                                  ? (_openBlock(), _createBlock("img", _hoisted_16))
                                  : (_ctx.rank <= 1799)
                                    ? (_openBlock(), _createBlock("img", _hoisted_17))
                                    : (_ctx.rank <= 1899)
                                      ? (_openBlock(), _createBlock("img", _hoisted_18))
                                      : (_ctx.rank <= 1999)
                                        ? (_openBlock(), _createBlock("img", _hoisted_19))
                                        : (_ctx.rank <= 2100)
                                          ? (_openBlock(), _createBlock("img", _hoisted_20))
                                          : (_openBlock(), _createBlock("span", _hoisted_21))
}