
import { computed, defineComponent, PropType, ref } from 'vue';

import RoleIcon from '@/components/svg/RoleIcon.vue';
import RankIcon from '@/components/svg/RankIcon.vue';
import { ClassType } from '@/objects/player';

type RoleDecription = {
  primary: string;
  secondary: string;
};

type RoleDescriptions = { [role: string]: RoleDecription };

export default defineComponent({
  name: 'EditRole',
  props: {
    rtype: String,
    role: Object as PropType<ClassType>,
  },
  components: { RoleIcon, RankIcon },
  setup(props, { emit }) {
    const role = computed(() => props.role);
    const mRole = ref(role);

    const roles: RoleDescriptions = {
      tank: { primary: 'Main Tank', secondary: 'Off Tank' },
      support: { primary: 'Heal', secondary: 'Light Heal' },
      dps: { primary: 'Hitscan', secondary: 'Projectile' },
    };

    const inpChange = (e: Event) => {
      const value = (e.target as HTMLInputElement).valueAsNumber || 0;
      emit('update-rank', props.rtype, value);
    };

    const specializationChange = (e: Event, specialization: 'primary' | 'secondary') => {
      const { checked } = e.target as HTMLInputElement;

      if (specialization === 'primary' && checked && mRole.value?.secondary) {
        emit('update-specialization', props.rtype, 'secondary', false);
      }

      if (specialization === 'secondary' && checked && mRole.value?.primary) {
        emit('update-specialization', props.rtype, 'primary', false);
      }

      emit('update-specialization', props.rtype, specialization, checked);
    };

    const changeSpecialization = (specialization: 'primary' | 'secondary') => (e: Event) => specializationChange(e, specialization);

    const changeSpecializationPrimary = changeSpecialization('primary'); 
    const changeSpecializationSecondary = changeSpecialization('secondary'); 

    return { roles, mRole, inpChange, changeSpecializationPrimary, changeSpecializationSecondary };
  },
});
