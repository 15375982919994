
import { Identity } from '@/objects/player';
import { computed, defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'EditIdentity',
  props: {
    identity: Object as PropType<Identity>,
  },
  setup(props) {
    const identity = computed(() => props.identity);
    const mIdentity = ref(identity);

    return { mIdentity };
  },
});
