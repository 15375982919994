import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-2" }
const _hoisted_2 = { class: "ms-2" }
const _hoisted_3 = { class: "ms-2" }
const _hoisted_4 = { class: "ms-2" }
const _hoisted_5 = { class: "ms-2" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("span", null, "Min SR: " + _toDisplayString(_ctx.minSr), 1),
    _createVNode("span", _hoisted_2, "Max SR: " + _toDisplayString(_ctx.maxSr), 1),
    _createVNode("span", _hoisted_3, "Difference: " + _toDisplayString(_ctx.maxSr - _ctx.minSr), 1),
    _createVNode("span", _hoisted_4, "Average: " + _toDisplayString(_ctx.avgSr), 1),
    _createVNode("span", _hoisted_5, "Off roles: " + _toDisplayString(_ctx.offRolesPercentage) + "%", 1)
  ]))
}