
import { defineComponent } from 'vue';

import AddPlayer from '@/components/Lobby/AddPlayer.vue';
import PlayerList from '@/components/Lobby/PlayerList.vue';
import DeletePlayer from '@/components/Lobby/DeletePlayer.vue';

export default defineComponent({
  name: 'BackupLobby',
  components: { AddPlayer, PlayerList, DeletePlayer },
})
