
import { defineComponent, PropType, ref } from 'vue';

import { useStore } from '@/store';
import MutationTypes from '@/store/mutation-types';
import PlayerEditor, { LobbyType } from '@/objects/player';

import GenerateRandoms from '@/components/Lobby/GenerateRandoms.vue';

export default defineComponent({
  name: 'AddPlayer',
  components: { GenerateRandoms },
  props: {
    enableRandoms: {
      type: Boolean,
      default: true,
    },
    lobby: {
      type: String as PropType<LobbyType>,
      default: 'players'
    },
  },
  setup(props) {
    const store = useStore();
    const playerTag = ref('');

    function addPlayer() {
      const player = PlayerEditor.createDefaultPlayer(playerTag.value);

      store.commit(MutationTypes.ADD_PLAYER, { player, lobby: props.lobby });
      store.commit(MutationTypes.EDIT_PLAYER, { playerId: player.identity.uuid, lobby: props.lobby });
      playerTag.value = '';
    }

    return {
      addPlayer,
      playerTag,
    };
  },
});
