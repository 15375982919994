import { renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  return (_openBlock(), _createBlock("li", null, [
    _createVNode("a", {
      href: "#",
      class: "dropdown-item",
      onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('drop-click')))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}