
import { computed, defineComponent, ref } from 'vue';

import { useStore } from '@/store';

import AdjustRole from './AdjustRole.vue';

export default defineComponent({
  name: 'AdjustRating',
  components: { AdjustRole },
  setup() {
    const store = useStore();
    const storeOptions = computed(() => store.state.balancerOptions.adjustSr);
    const adjustSr = ref(storeOptions);

    return { adjustSr };
  },
});
