
import { computed, defineComponent } from 'vue';

import Utils from '@/utils';
import Import from '@/components/Balance/Import.vue';
import DownloadIcon from '@/components/svg/DownloadIcon.vue';
import { useStore } from '@/store';

export default defineComponent({
  name: 'Sync',
  components: { Import, DownloadIcon },
  setup() {
    const store = useStore();
    const options = computed(() => store.state.balancerOptions);

    const download = () => {
      const data = options.value;
      const exportData = {
        format: 'xvb-1',
        data,
      };

      Utils.download(
        `balancerOptions-${new Date().toLocaleString('ru-RU')}.json`,
        JSON.stringify(exportData)
      );
    };

    return { download };
  },
});
