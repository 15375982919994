
import { defineComponent, ref } from 'vue';
import Modal from '@/components/Helpers/Modal.vue';

export default defineComponent({
  name: 'ExportModal',
  components: { Modal },
  props: {
    isActive: Boolean,
    exportText: String,
  },
  setup() {
    const tarea = ref(null);

    const copyText = () => {
      const area = tarea.value as HTMLInputElement | null;

      if (!area) return;

      area.select();
      document.execCommand('copy');
    };

    return { tarea, copyText };
  },
});
