
import { defineComponent, PropType } from 'vue';

import { useStore } from '@/store';
import MutationTypes from '@/store/mutation-types';
import { LobbyType } from '@/objects/player';

export default defineComponent({
  name: 'DeletePlayer',
  props: {
    lobby: {
      type: String as PropType<LobbyType>,
      default: 'players'
    }
  },
  setup(props) {
    const store = useStore();

    const allowDrop = (ev: DragEvent) => {
      ev.preventDefault();
    };

    const drop = (ev: DragEvent) => {
      ev.preventDefault();
      const playerId = ev?.dataTransfer?.getData('playerTag');
      const teamUuid = ev?.dataTransfer?.getData('team');

      if (playerId === undefined) return;

      store.commit(MutationTypes.DELETE_PLAYER, { playerId, lobby: props.lobby });

      if (teamUuid) {
        store.commit(MutationTypes.REMOVE_FROM_TEAM, {
          teamUuid,
          playerId,
        });
      }
    };

    return {
      drop,
      allowDrop,
    };
  },
});
