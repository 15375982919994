<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    class="bi bi-shield-fill"
    fill="currentColor"
    viewBox="0 0 1057.000000 1280.000000"
    preserveAspectRatio="xMidYMid meet"
    role="presentation"
  >
    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M9770 12509 c-437 -159 -894 -323 -1015 -364 -121 -41 -241 -85 -268
-98 -45 -21 -168 -185 -2748 -3674 -1486 -2009 -2703 -3653 -2705 -3653 -3 0
-203 181 -445 403 -243 221 -444 403 -447 404 -7 2 -587 -793 -597 -818 -4
-10 150 -103 535 -324 343 -197 538 -315 535 -323 -15 -41 -74 -102 -114 -118
-46 -18 -100 -60 -130 -103 -17 -23 -18 -34 -9 -74 l10 -47 -69 -96 c-66 -93
-69 -96 -126 -111 -51 -14 -64 -24 -101 -71 -42 -53 -43 -54 -34 -104 l9 -50
-68 -103 -68 -104 -58 -6 c-56 -7 -59 -9 -104 -67 l-46 -60 12 -54 13 -55 -56
-67 c-73 -90 -81 -96 -150 -115 -32 -9 -59 -18 -60 -19 -1 -2 -19 -24 -40 -50
l-37 -46 14 -54 14 -55 -52 -80 c-55 -83 -70 -93 -144 -93 -21 0 -39 -14 -77
-60 -27 -33 -48 -67 -46 -76 2 -9 -79 60 -180 153 -387 359 -503 462 -509 455
-4 -4 -96 -169 -204 -367 -109 -198 -199 -363 -201 -367 -2 -3 105 -102 239
-219 133 -116 248 -218 256 -225 12 -11 1 -45 -74 -230 l-89 -217 30 -26 c229
-202 1028 -897 1035 -899 5 -2 81 50 169 115 88 65 169 124 179 131 16 11 45
-12 267 -207 l249 -219 55 51 c89 81 490 480 489 486 0 4 -141 134 -314 291
-172 157 -314 288 -314 293 -1 4 16 7 38 7 35 0 42 5 79 54 30 40 43 67 48
105 5 42 18 67 75 143 38 51 79 99 91 105 13 7 43 13 68 13 46 0 46 0 83 63
26 45 37 76 37 104 0 34 9 52 62 118 66 82 86 95 151 95 38 0 48 6 94 53 l52
52 -8 60 -9 60 68 75 c62 69 73 77 135 95 58 18 73 27 106 69 38 47 39 51 34
105 l-6 56 86 92 c79 85 89 92 139 103 46 9 60 18 92 57 30 36 39 58 46 105 7
52 39 118 58 118 5 0 271 -329 731 -903 15 -20 28 -5 332 359 174 208 317 382
317 385 0 3 -188 162 -417 353 -230 192 -419 351 -421 355 -1 4 717 840 1595
1857 879 1017 2237 2590 3019 3494 l1420 1645 97 1110 c53 610 95 1110 94
1111 -1 1 -360 -128 -797 -287z"
      />
    </g>
  </svg>
</template>
