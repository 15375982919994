
import { defineComponent, onMounted, ref } from 'vue';

import Dropdown from 'bootstrap/js/src/dropdown';

export default defineComponent({
  name: 'Dropdown',
  props: {
    id: String,
    title: String,
  },
  setup() {
    const dropdownRef = ref(null);

    onMounted(() => {
      // eslint-disable-next-line
      const a = new Dropdown(dropdownRef.value);
    });

    return { dropdownRef };
  },
});
