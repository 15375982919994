
import { defineComponent } from 'vue';

import TankIcon from '@/components/svg/TankIcon.vue';
import DamageIcon from '@/components/svg/DamageIcon.vue';
import SupportIcon from '@/components/svg/SupportIcon.vue';

export default defineComponent({
  name: 'RoleIcon',
  props: {
    rtype: String,
  },
  components: { TankIcon, DamageIcon, SupportIcon },
});
