import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dropdown" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("button", {
      class: "btn btn-sm btn-secondary dropdown-toggle",
      id: `dropdown_${_ctx.id}`,
      "data-bs-toggle": "dropdown",
      "aria-expanded": "false",
      ref: "dropdownRef"
    }, [
      _renderSlot(_ctx.$slots, "title", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.title), 1 /* TEXT */)
      ])
    ], 8, ["id"]),
    _createVNode("ul", {
      class: "dropdown-menu",
      "aria-labelledby": `dropdown_${_ctx.id}`
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, ["aria-labelledby"])
  ]))
}