
import { computed, defineComponent, PropType } from 'vue';
import { PlayerEntries } from '@/objects/player';

export default defineComponent({
  name: 'Stats',
  props: {
    players: Array as PropType<PlayerEntries>,
    currentCount: Number,
  },
  setup(props) {
    const squireCount = computed(
      () => props.players?.filter(([, player]) => player.identity.isSquire === true).length
    );
    const captainCount = computed(
      () => props.players?.filter(([, player]) => player.identity.isCaptain === true).length
    );

    return { squireCount, captainCount };
  },
});
