
import { computed, defineComponent, PropType, ref } from 'vue';
import { Team } from '@/objects/team';
import { useStore } from '@/store';
import debounce from 'lodash/debounce';

import TeamRoles from '@/components/Teams/TeamRoles.vue';
import MutationTypes from '@/store/mutation-types';

export default defineComponent({
  name: 'Team',
  props: {
    teamId: Number,
    team: Object as PropType<Team>,
  },
  components: { TeamRoles },
  setup(props) {
    const store = useStore();
    const players = computed(() => store.state.players);
    const teamUuid = computed(() => props.team?.uuid);
    const teamAverage = computed(() => {
      if (!props.team) return 0;

      if (!store.state.showBalancerSR) {
        return Math.round(
          props.team?.members.reduce(
            (acc, member) => acc + store.state.players[member.uuid].stats.classes[member.role].rank,
            0
          ) / props.team?.members.length
        );
      }

      return Math.round(props.team?.avgSr);
    });
    const cTeam = computed(() => store.state.teams.find(team => team.uuid === teamUuid.value));
    const mTeam = ref(cTeam);

    const tanks = computed(() => mTeam.value?.members.filter(member => member.role === 'tank'));
    const dps = computed(() => mTeam.value?.members.filter(member => member.role === 'dps'));
    const supports = computed(() =>
      mTeam.value?.members.filter(member => member.role === 'support')
    );

    const updateTeam = debounce((e: Event) => {
      const teamName = (e.target as HTMLInputElement).value;

      if (cTeam.value && teamName) {
        store.commit(MutationTypes.UPDATE_TEAM_NAME, {
          teamUuid: cTeam.value.uuid,
          teamName,
        });
      }
    }, 1000);

    const removeTeam = () => {
      if (!cTeam.value) return;
      store.commit(MutationTypes.REMOVE_TEAM, cTeam.value.uuid);
    };

    return {
      tanks,
      dps,
      supports,
      players,
      teamUuid,
      mTeam,
      teamAverage,
      updateTeam,
      removeTeam,
    };
  },
});
