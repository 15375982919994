import { resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "position-relative w-100" }

export function render(_ctx, _cache) {
  const _component_sync = _resolveComponent("sync")
  const _component_balance_type = _resolveComponent("balance-type")
  const _component_balance_disable = _resolveComponent("balance-disable")
  const _component_balance_options = _resolveComponent("balance-options")
  const _component_adjust_rating = _resolveComponent("adjust-rating")
  const _component_dispersion = _resolveComponent("dispersion")
  const _component_tries_count = _resolveComponent("tries-count")
  const _component_balancer_progress = _resolveComponent("balancer-progress")
  const _component_modal = _resolveComponent("modal")

  return (_openBlock(), _createBlock(_component_modal, {
    title: "Balance",
    variant: "large",
    isActive: _ctx.isActive,
    customAction: "Balance",
    fullscreen: "md-down",
    "onClose-modal": _ctx.closeModal,
    "onSave-changes": _ctx.balance
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_sync)
      ]),
      _createVNode(_component_balance_type, {
        modelValue: _ctx.balanceType,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (_ctx.balanceType = $event))
      }, null, 8, ["modelValue"]),
      (_ctx.balanceType === 'full')
        ? _createVNode(_component_balance_disable, {
            key: 0,
            modelValue: _ctx.disableType,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (_ctx.disableType = $event))
          }, null, 8, ["modelValue"])
        : _createCommentVNode("", true),
      _createVNode(_component_balance_options),
      _createVNode(_component_adjust_rating),
      _createVNode(_component_dispersion),
      _createVNode(_component_tries_count),
      _createVNode(_component_balancer_progress, { progress: _ctx.progress }, null, 8, ["progress"])
    ]),
    _: 1
  }, 8, ["isActive", "onClose-modal", "onSave-changes"]))
}