import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", null, "Total: " + _toDisplayString(_ctx.players.length), 1),
    _createVNode("div", null, "Seen: " + _toDisplayString(_ctx.currentCount), 1),
    _createVNode("div", null, "Cap's: " + _toDisplayString(_ctx.captainCount), 1),
    _createVNode("div", null, "Squires: " + _toDisplayString(_ctx.squireCount), 1)
  ]))
}